import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disable-fields"
export default class extends Controller {
  static targets = ["field", "toggleButton", "container"];
  connect() {}

  // This method is called when the checkbox is clicked
  toggleFields() {
    const isChecked = this.toggleButtonTarget.checked;

    this.fieldTargets.forEach((field) => {
      field.disabled = isChecked;
    });

    this.applyOpacity();
  }


  applyOpacity() {
    const isChecked = this.toggleButtonTarget.checked;
    this.containerTarget.classList.toggle("opacity-50", isChecked);
  }
}
