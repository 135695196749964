import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mask-input"
export default class extends Controller {
  static targets = ["input"]

  connect() {
    if (this.hasInputTarget) {
      this.inputTarget.addEventListener('input', this.applyMask.bind(this))
      this.inputTarget.value = this.applyPhoneMask(this.inputTarget.value.replace(/\D/g, ''))
    }
    this.element.addEventListener('submit', this.cleanInput.bind(this))
  }

  applyMask(event) {
    let value = event.target.value.replace(/\D/g, '')
    value = this.applyPhoneMask(value)
    event.target.value = value
  }

  cleanInput(event) {
    this.inputTarget.value = this.cleanPhoneMask(this.inputTarget.value)
  }

  applyPhoneMask(value) {
    if (value.length > 11) {
      value = value.slice(0, 11)
    }
    if (value.length > 2) {
      value = `(${value.slice(0, 2)}) ${value.slice(2)}`
    }
    if (value.length > 9) {
      value = `${value.slice(0, 9)}-${value.slice(9)}`
    }
    return value
  }

  cleanPhoneMask(value) {
    return value.replace(/\D/g, '')
  }
}


