import BulkController from "./bulk_controller";
import FlashController from "./flash_controller"; // Importar o controlador de flash

export default class extends BulkController {
  static targets = ["checkbox", "selectAll"]

  connect() {
    super.connect();
    this.resetCheckboxes();  // Resetar checkboxes ao conectar
    this.updateBulkButtons();
  }

  change(event) {
    super.change(event);
    this.updateBulkButtons();
  }

  toggleSelectAll(event) {
    super.toggleSelectAll(event);
    this.updateBulkButtons();
  }

  // Função para atualizar o estado dos botões (status e renovar)
  updateBulkButtons() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const bulkReturnButton = this.getButton('return', isMobile);
    const bulkRenewButton = this.getButton('renew', isMobile);

    const selectedIds = this.selectedIds;

    if (bulkReturnButton && bulkRenewButton) {
      bulkReturnButton.dataset.selectedIds = JSON.stringify(selectedIds);
      bulkRenewButton.dataset.selectedIds = JSON.stringify(selectedIds);

      if (selectedIds.length === 0) {
        bulkReturnButton.classList.add("hidden");
        bulkRenewButton.classList.add("hidden");
      } else {
        bulkReturnButton.classList.remove("hidden");
        bulkRenewButton.classList.remove("hidden");
      }
    } else {
      console.error("Bulk action button not found");
    }
  }

  get selectedIds() {
    return this.checkboxTargets.filter(target => target.checked).map(target => target.value);
  }

  // Função genérica para lidar com as ações de atualização e renovação
  handleBulkAction(event) {
    event.preventDefault();
    const actionType = event.target.dataset.actionType;
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const button = this.getButton(actionType, isMobile);

    if (!button) {
      alert(this.getAlertMessage(actionType));
      return;
    }

    const url = button.getAttribute("data-url");
    const selectedIds = JSON.parse(button.dataset.selectedIds || '[]');

    if (selectedIds.length === 0) {
      FlashController.setFlashMessage('Nenhum aluguel selecionado.');
      return;
    }

    const confirmationMessage = this.getConfirmationMessage(actionType);

    if (confirm(confirmationMessage)) {
      this.performAction(url, selectedIds, actionType);
    }
  }

  getButton(actionType, isMobile) {
    switch (actionType) {
      case 'return':
        return isMobile
          ? document.getElementById("bulk-return-button-rent-mobile")
          : document.getElementById("bulk-return-button-rent-desktop");
      case 'renew':
        return isMobile
          ? document.getElementById("bulk-renew-button-rent-mobile")
          : document.getElementById("bulk-renew-button-rent-desktop");
      default:
        console.error("Invalid action type");
        return null;
    }
  }

  getConfirmationMessage(actionType) {
    switch (actionType) {
      case 'return':
        return 'Você tem certeza que deseja marcar todos os alugueis selecionados como Devolvido?';
      case 'renew':
        return 'Você tem certeza que deseja renovar todos os alugueis selecionados?';
      default:
        return 'Você tem certeza que deseja realizar esta ação?';
    }
  }

  getAlertMessage(actionType) {
    switch (actionType) {
      case 'return':
        return 'Bulk return button not found';
      case 'renew':
        return 'Bulk renew button not found';
      default:
        return 'Invalid action type';
    }
  }

  performAction(url, selectedIds, actionType) {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      },
      body: JSON.stringify({ rent_ids: selectedIds })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          FlashController.setFlashMessage(`${this.getSuccessMessage(actionType)} com sucesso!`, "success");
          FlashController.reloadPage();
        } else {
          FlashController.setFlashMessage(data.message, "warning");
          FlashController.reloadPage();
        }
      })
      .catch(error => {
        console.error(`Erro ao ${this.getErrorMessage(actionType)} alugueis:`, error);
        FlashController.setFlashMessage(`Ocorreu um erro ao ${this.getErrorMessage(actionType)} os alugueis.`, "warning");
        FlashController.reloadPage();
      });
  }

  getSuccessMessage(actionType) {
    switch (actionType) {
      case 'return':
        return 'Status atualizado';
      case 'renew':
        return 'Aluguéis renovados';
      default:
        return 'Ação realizada';
    }
  }

  getErrorMessage(actionType) {
    switch (actionType) {
      case 'return':
        return 'atualizar';
      case 'renew':
        return 'renovar';
      default:
        return 'realizar a ação';
    }
  }

  resetCheckboxes() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false;
    });
    this.selectAllTarget.checked = false; // Reseta o checkbox de "Selecionar Todos"
    this.updateBulkButtons(); // Atualiza o estado dos botões
  }
}
